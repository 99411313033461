import { computed, defineComponent, inject } from 'vue';
import WhatBoxGroup from '@/components/WhatBoxGroup.vue';
import WhatLoading from '@/components/WhatLoading.vue';
import WhatLoadingMobile from '@/components/WhatLoadingMobile.vue';
export default defineComponent({
    components: {
        WhatBoxGroup: WhatBoxGroup,
        WhatLoading: WhatLoading,
        WhatLoadingMobile: WhatLoadingMobile,
    },
    setup: function () {
        var whatStore = inject('whatStore');
        return {
            loading: computed(function () { return whatStore.state.whatState.loading; }),
        };
    },
});
