import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        img: { type: String, default: null },
        title: { type: String, default: null },
        commentsNumber: { type: Number || undefined, default: null },
        lastCommentOwn: { type: String, default: null },
        previewText: { type: String, default: null },
    },
});
