var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue';
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue';
import { useRouter } from 'vue-router';
import WhatBox from '@/components/WhatBox.vue';
export default defineComponent({
    components: {
        WhatBox: WhatBox,
        IonInfiniteScroll: IonInfiniteScroll,
        IonInfiniteScrollContent: IonInfiniteScrollContent,
    },
    setup: function () {
        var _this = this;
        var whatStore = inject('whatStore');
        var isInfiniteScrollDisabled = ref(false);
        var router = useRouter();
        var infinityPage = ref(0);
        var loadData = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        infinityPage.value++;
                        return [4 /*yield*/, whatStore.getWhatPosts(infinityPage.value)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, whatStore.getWhatPostsCommentsCount({
                                postIds: whatStore.state.whatState.posts.map(function (o) { return o.identifier; }),
                            })];
                    case 2:
                        _a.sent();
                        e.target.complete();
                        if (infinityPage.value == whatStore.state.whatState.maxPages - 1) {
                            isInfiniteScrollDisabled.value = true;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var openWhatPost = function (id) {
            router.push({ name: 'what.post', params: { id: id } });
        };
        var getTextFromHtml = function (html) {
            var _a, _b;
            var div = document.createElement('div');
            div.innerHTML = html;
            return (((_a = div.querySelector('p')) === null || _a === void 0 ? void 0 : _a.textContent) ||
                ((_b = div.querySelector('p')) === null || _b === void 0 ? void 0 : _b.innerText) ||
                '');
        };
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, whatStore.getWhatPosts(0)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, whatStore.getWhatPostsCommentsCount({
                                postIds: whatStore.state.whatState.posts.map(function (o) { return o.identifier; }),
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        return {
            whatState: computed(function () { return whatStore.state.whatState; }),
            loadData: loadData,
            isInfiniteScrollDisabled: isInfiniteScrollDisabled,
            openWhatPost: openWhatPost,
            getTextFromHtml: getTextFromHtml,
        };
    },
});
